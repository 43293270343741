import _uniq from 'lodash/uniq';
import _difference from 'lodash/difference';
import dayjs from 'dayjs';

import type { IGroupMessages, IMessage } from 'src/types';
import LocalStorageItem from 'src/utils/storage/LocalStorageItem';
import { type SpecialMentionTypeENUM } from '../types/quillTypes';

export type PendingMessagesType = {
  sending: IMessage[];
  errored: IMessage[];
};

const pendingMessagesStorage = new LocalStorageItem<{
  [userId: string]: {
    channel: {
      [channelId: string]: PendingMessagesType;
    };
    thread: {
      [parentMessageId: string]: PendingMessagesType;
    };
  };
}>({
  key: 'chat_pending-messages',
});

/**
 * To clear from all potential garbage
 */
const clearStorage = () => {
  const data = {};
  // TODO: need to check and remove if neccessary

  // const handleChatOrThreadData = (channelOrThreadData: Record<string, PendingMessagesType>) => {
  //   const formattedData = { ...channelOrThreadData };

  //   Object.entries(formattedData).forEach(([channelOrThreadId, { sending, errored }]) => {
  //     if (!sending.length && !errored.length) {
  //       delete formattedData[channelOrThreadId];
  //     }
  //   });

  //   return Object.keys(formattedData).length;
  // };
  // Object.entries(data).forEach(([userId, { channel, thread }]) => {
  //   const channelsCount = handleChatOrThreadData(channel);
  //   const threadsCount = handleChatOrThreadData(thread);
  //   if (!channelsCount && !threadsCount) {
  //     delete data[userId];
  //   }
  // });

  pendingMessagesStorage.set(data);
};
clearStorage();

export const getPendingMessages = (type: 'channel' | 'thread', channelOrThreadId: number, userId: number): PendingMessagesType => {
  const storedData = pendingMessagesStorage.get();
  const sendingMessages = storedData?.[userId]?.[type]?.[channelOrThreadId]?.sending || [];
  const erroredMessages = storedData?.[userId]?.[type]?.[channelOrThreadId]?.errored || [];
  const reduceMessagesByMedia = (messages: IMessage[]) => {
    const messagesData = messages.reduce((acc, message) => {
      if (message.media?.length) {
        acc.withMedia.push(message);
      } else {
        acc.withoutMedia.push(message);
      }

      return acc;
    }, { withMedia: [], withoutMedia: [] } as {
      withMedia: IMessage[];
      withoutMedia: IMessage[];
    });

    return messagesData;
  };
  const sendingMessagesData = reduceMessagesByMedia(sendingMessages);
  const erroredMessagesData = reduceMessagesByMedia(erroredMessages);

  if (sendingMessagesData.withMedia?.length || erroredMessagesData.withMedia.length) {
    setPendingMessages(
      type,
      channelOrThreadId,
      userId,
      {
        sending: sendingMessagesData.withoutMedia || [],
        errored: erroredMessagesData.withoutMedia || [],
      },
    );
  }
  const updatedStoredMessages = pendingMessagesStorage.get();
  const data = updatedStoredMessages?.[userId]?.[type]?.[channelOrThreadId] || { sending: [], errored: [] };

  return data;
};

export const setPendingMessages = (type: 'channel' | 'thread', channelOrThreadId: number, userId: number, data: PendingMessagesType) => {
  const storedData = pendingMessagesStorage.get() || {};
  if (!storedData[userId]) {
    storedData[userId] = { channel: {}, thread: {} };
  }
  if (!storedData[userId]?.[type]) {
    storedData[userId] = { channel: {}, thread: {} };
  }

  const formattedData = { ...data };
  formattedData.sending = formattedData.sending.map((message) => {
    const formattedMessage = { ...message };

    if (formattedMessage.media?.length) {
      formattedMessage.media = formattedMessage.media.filter((mediaItem) => !mediaItem.isTemporary);
    }

    return formattedMessage;
  });

  storedData[userId][type][channelOrThreadId] = formattedData;

  pendingMessagesStorage.set(storedData);
};

const getMentionUsersIds = (value: string) => {
  const tagsAndStringRegex = /(<[^>]+>)/gi;
  const tags = value.split(tagsAndStringRegex).filter((item) => item.startsWith('<span class="mention"'));
  const ids: (number | SpecialMentionTypeENUM)[] = [];
  for (const tag of tags) {
    const dataId = tag.split(' ').find((item) => item.startsWith('data-id'));
    if (dataId) {
      const id = dataId.replace(/(^data-id=")|"$/g, '');

      let formattedId: number | SpecialMentionTypeENUM;
      if (/^\d+$/.test(id)) {
        formattedId = +id;
      } else {
        formattedId = id as SpecialMentionTypeENUM;
      }

      ids.push(formattedId);
    }
  }

  return ids;
};

export const getMentionsFromText = (text: string, oldText?: string) => {
  const newMentions = _uniq(getMentionUsersIds(text));

  if (typeof oldText !== 'string') {
    return {
      add: newMentions,
      remove: [],
    };
  }

  const oldMentions = _uniq(getMentionUsersIds(oldText));
  const addUserMentionsIds = _difference(newMentions, oldMentions);
  const removeUserMentionsIds = _difference(oldMentions, newMentions);

  return {
    add: addUserMentionsIds,
    remove: removeUserMentionsIds,
  };
};

export const groupMessages = (data: {
  messages: number[];
  messagesObject: Record<string, IMessage>;
  pendingMessages: PendingMessagesType;
}): IGroupMessages[] => {
  const grouppedMessagesObject: Record<string, {
    date?: string;
    key: string;
    messages: IMessage[];
  }> = {};
  data.messages.forEach((messageId) => {
    const message = data.messagesObject[messageId];
    if (!message) {
      return console.warn('Message is empty');
    }
    const dayString = dayjs(message.createdAt).format('YYYY-MM-DD 00:00');

    if (!grouppedMessagesObject[dayString]) {
      grouppedMessagesObject[dayString] = {
        key: dayString,
        date: dayString,
        messages: [],
      };
    }
    grouppedMessagesObject[dayString].messages.push(message);
  });

  const grouppedMessages = Object.values(grouppedMessagesObject);

  grouppedMessages.sort((a, b) => {
    return dayjs(a.date).unix() - dayjs(b.date).unix();
  });

  grouppedMessages.push(
    { key: 'errored', messages: data.pendingMessages.errored },
    { key: 'sending', messages: data.pendingMessages.sending },
  );

  return grouppedMessages;
};

export const errorAllPendtingMessages = (pendingMessages: PendingMessagesType) => {
  const newPendingMessages = {
    sending: [],
    errored: [
      ...pendingMessages.errored,
      ...pendingMessages.sending.map((message) => ({
        ...message,
        isSending: false,
        isError: true,
      })),
    ],
  };
  return newPendingMessages;
};
