/** Interface copied from electron d.ts file */
export interface INotificationConstructorOptions {
  /**
   * A title for the notification, which will be displayed at the top of the
   * notification window when it is shown.
   */
  title?: string;
  /**
   * A subtitle for the notification, which will be displayed below the title.
   *
   * @platform darwin
   */
  subtitle?: string;
  /**
   * The body text of the notification, which will be displayed below the title or
   * subtitle.
   */
  body?: string;
  /**
   * Whether or not to suppress the OS notification noise when showing the
   * notification.
   */
  silent?: boolean;
  /**
   * Whether or not to add an inline reply option to the notification.
   *
   * @platform darwin
   */
  hasReply?: boolean;
  /**
   * The timeout duration of the notification. Can be 'default' or 'never'.
   *
   * @platform linux,win32
   */
  timeoutType?: ('default' | 'never');
  /**
   * The placeholder to write in the inline reply input field.
   *
   * @platform darwin
   */
  replyPlaceholder?: string;
  /**
   * The name of the sound file to play when the notification is shown.
   *
   * @platform darwin
   */
  sound?: string;
  /**
   * The urgency level of the notification. Can be 'normal', 'critical', or 'low'.
   *
   * @platform linux
   */
  urgency?: ('normal' | 'critical' | 'low');

  /**
   * A custom title for the close button of an alert. An empty string will cause the
   * default localized text to be used.
   *
   * @platform darwin
   */
  closeButtonText?: string;
}

export interface IElectronAPI {
  showNotification: (message: string) => void;
  setAppBadge: (badgeValue: string) => void;
  openExternalLink: (url: string) => void;
  getAppVersion: () => string;
  updateAppModal: (params: string) => boolean;
  showSystemMessage: (params: string) => boolean;
  downloadFile: (data: string) => Promise<string>;
  abortDownloadFile: (data: string) => void;
  openFile: (data: string) => string;
}

export interface IElectronModalParams {
  message: string;
  title: string;
  yesButtonText?: string;
  noButtonText?: string;
  textWidth?: number;
}

export enum ElectronEventsENUM {
  notificationClick = '__electron__notification-click',
  updatesReady = '__electron__app-updates-ready',
  appResume = '__electron__app-resume',
  appSuspend = '__electron__app-suspend', // should fire when system goes to sleep
}
