import React from 'react';
import { useTranslation } from 'react-i18next';

import ModuleLink from './ModuleLink';
import ModuleLinksStyle from './ModuleLinks.styles';
import Protector from 'src/ui/components/Protector';

import { ROUTES } from 'src/utils/constants';
import hrm from '../assets/hrm.svg';
import hrmIsActive from '../assets/hrmIsActive.svg';
import messenger from '../assets/messenger.svg';
import messengerIsActive from '../assets/messengerIsActive.svg';
import { UserPermissionsENUM } from 'src/types';

const ModuleLinks: React.FC = () => {
  const { t } = useTranslation('general');

  return (
    <ModuleLinksStyle>
      <Protector permissions={[UserPermissionsENUM.hrm__available]}>
        <ModuleLink
          to={ROUTES.employees.createPath()}
          routeToMatch={ROUTES.hrm.createPath()}
          routeKey="hrm"
          icon={hrm}
          iconIsActive={hrmIsActive}
          toolTip={t('sidebar.hrm')}
        />
      </Protector>

      <ModuleLink
        to={ROUTES.chat.createPath('*')}
        routeToMatch={ROUTES.chat.createPath('*')}
        routeKey="chat"
        icon={messenger}
        iconIsActive={messengerIsActive}
        toolTip={t('sidebar.chat')}
      />
    </ModuleLinksStyle >
  );
};

export default ModuleLinks;
