import styled from 'styled-components';

const StyledSelectedUserCard = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 8px;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.white.A5};
  border: 1px solid ${({ theme }) => theme.palette.select.cardBackground};
  border-radius: 6px;
  height: 32px;
  margin: 2px;
  gap: 8px;

  .user-card__text {
    font-weight: ${({ theme }) => theme.font.weight.xlg};
  }

  .user-card__avatar {
    width: 20px;
    height: 20px;
    font-size: ${({ theme }) => theme.font.size.xxs};
    cursor: auto;
  }

  .user-card__icon-button {
    width: 18px;
    height: 18px;
    padding: 0px;
  }
`;

export default StyledSelectedUserCard;
