import { createAsyncThunk } from '@reduxjs/toolkit';
import userApi from 'src/api/userApi';
import { UserStatusENUM, type IUser } from 'src/types';
import type { AppStateType } from '../store';

type GetUserListRequestParams = Parameters<typeof userApi['getList']>[0];

const getUserCollectionThunk = createAsyncThunk<IUser[], GetUserListRequestParams, { state: AppStateType }>(
  'usersCollection/getUserCollection',
  async (reqParams) => {
    const { data: { payload } } = await userApi.getList({
      ...reqParams,
      status: UserStatusENUM.active,
      fullList: true,
    });
    return payload;
  },
);

export const userCollectionThunks = {
  getUserCollectionThunk,
};
