import React from 'react';

export const NAVIGATE_ALL = '__tab_navigate_all__';
export const LOGOUT_ALL = '__tab_logout_all__';

export const navigateAllTabs = (url: string) => {
  const id = Math.random().toString(36).slice(2);
  const valueToSet = JSON.stringify({ id, url });
  localStorage.setItem(NAVIGATE_ALL, valueToSet);
};

export const logoutAllTabs = () => {
  const id = Math.random().toString(36).slice(2);
  localStorage.setItem(LOGOUT_ALL, id);
};

export const useCrossTabListener = () => {
  React.useEffect(() => {
    window.addEventListener('storage', (ev) => {
      if (ev.key === NAVIGATE_ALL && ev.newValue) {
        try {
          const { url } = JSON.parse(ev.newValue);
          window.location.replace(url);
        } catch (err) {
          console.warn('Cant reload tab because of parse error');
        }
      }
      if (ev.key === LOGOUT_ALL && ev.newValue) {
        window.location.reload();
      }
    });
  }, []);
};
