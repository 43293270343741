import type { PropsWithChildren } from 'react';
import React from 'react';
import CreatingChannelForm from './CreateChannelForm';

/* eslint-disable @typescript-eslint/no-empty-function */
const CreateChannelContext = React.createContext<
  { isOpen: boolean; changeStatus(status: boolean): void; addHandlerOnCreatedChannel(cb: () => void): () => void }>({
    isOpen: false,
    changeStatus() { },
    addHandlerOnCreatedChannel() { return () => { }; },
  });

export const CreatingChannelModalProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const changeStatus = React.useCallback((status: boolean) => setIsOpen(status), []);

  const listener = React.useRef<() => void>();

  const addHandlerOnCreatedChannel = React.useCallback((cb: () => void) => {
    listener.current = cb;
    return () => {
      listener.current = undefined;
    };
  }, []);
  const onChannelCreated = () => {
    listener.current?.();
  };

  return (
    <CreateChannelContext.Provider value={{ isOpen, changeStatus, addHandlerOnCreatedChannel }}>
      {children}
      <CreatingChannelForm
        isOpen={isOpen}
        onClose={() => changeStatus(false)}
        onChannelCreated={onChannelCreated}
      />
    </CreateChannelContext.Provider>
  );
};

export const useCreateChannelModalContext = () => React.useContext(CreateChannelContext);
