import styled from 'styled-components';

type StylesPropsType = {
  isVisible: boolean;
  isHideLabel?: boolean;
  isAddUsers: boolean;
};

const StyledUserMultiSelect = styled.div<StylesPropsType>`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;

  .select {
    position: relative;
    height: 330px;
  }

  .select__input {
    border: none;
  }

  .select__container {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    align-items: center;
    gap: 5px;
  }

  .select__text-field {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    border: none;
    font-size: ${({ theme }) => theme.font.size.xs};
  }

  .select__text-input {
    font-size: ${({ theme }) => theme.font.size.xs};
    padding: 0;
  }

  .select__select {
    display: flex;
    flex-shrink: 0;
    flex-basis: 0;
    flex-direction: column;
    max-width: 100%;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    gap: 5px;
    z-index: 100;
    max-height: 144px;
    padding-top: 10px;
    border-radius: 6px;
  }

  .list-container {
    display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
    padding: 16px 0px 0px;
    z-index: 20;
    max-height: ${({ isAddUsers }) => (isAddUsers ? '243px' : '200px')};
    background: ${({ theme, isAddUsers }) => (isAddUsers ? theme.palette.white.A5 : theme.palette.select.optionBackground.secondary)};
    border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: ${({ isAddUsers }) => (isAddUsers ? '0px' : '4px')};
    min-height: 165px;
    flex-grow: 1;
  }

  .list-container--absolute {
    position: absolute;
  }

  .select__list {
    width: 100%;

    flex-direction: column;
    gap: 10px;
    overflow: auto;
    flex-shrink: 2;
    &::-webkit-scrollbar {
      width: 8px;
      visibility: hidden;
    }
  }

  .select__list--middle {
    max-height: 400px;
  }

  .select__list--is-loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .select__no-result {
    padding: 4px 16px;
    font-size: ${({ theme }) => theme.font.size.sm};
    font-weight: ${({ theme }) => theme.font.weight.md};
    color: ${({ theme }) => theme.palette.grey[500]};
  }

  .loader-icon {
    width: 30px;
    height: 30px;
  }

  .custom-input__label-top {
    color: ${({ theme, isHideLabel }) => (isHideLabel
    ? theme.palette.action.disabledBorder
    : theme.palette.grey[200])};
  }

  .custom-input__wrapper {
    padding: 4px 6px;
    border-radius: 6px;
  }
`;

export default StyledUserMultiSelect;
