import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'src/store/store';
import { ElectronAPI } from 'src/utils/electronAPI';
import type { InAppNotificationPayload } from './types';
// import generalWs from 'src/api/ws/generalWs';
import ChatRouter from '../../pages/Chat/utils/router.utils';
import { crossWorkspaceWs } from 'src/api/ws/crossWorkspaceWs';
import helpers from 'src/utils/helpers';

const InAppNotifications = () => {
  const isSocketConnected = useAppSelector((state) => state.main.socketData.isConnected);
  const companyId = useAppSelector((state) => state.main.user?.company?.companyId);

  const navigate = useNavigate();
  React.useEffect(() => {
    if (ElectronAPI.isElectronApp) {
      const unSubscribe = crossWorkspaceWs.subscribeOnDesktopNotification((data) => {
        ElectronAPI.actions.showNotification({
          title: data.companyName,
          body: data.body,
          subtitle: data.title,
          extraData: {
            ...data,
          },
        });
      });

      const returnFn = ElectronAPI.events.onNotificationClick<InAppNotificationPayload>((data) => {
        if (data.type !== 'chatMessage') {
          return;
        }
        const pathToNavigate = ChatRouter.getChatUrl({
          channelId: data.channelId,
          messageId: data.messageId,
          parentMessageId: data.parentMessageId,
        });

        if (companyId !== data.companyId) {
          helpers.changeWorkspace(data.companyId, true).then(() => {
            window.location.href = `${window.location.origin}${pathToNavigate}`;
          });
          return;
        }
        navigate(pathToNavigate);
      });
      return () => {
        unSubscribe();
        returnFn?.();
      };
    }
  }, [isSocketConnected, companyId]);

  return null;
};

export default InAppNotifications;
