import React from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import YupString from 'yup/lib/string';
import YupObject from 'yup/lib/object';
import YupBoolean from 'yup/lib/boolean';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import styled from 'styled-components';

import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import CustomInput from 'src/ui/components/CustomInput';
import ActionButtons from 'src/ui/components/ActionButtons';
import CustomEmojiPicker from 'src/ui/pages/Chat/components/EmojiPicker';
import { ReactComponent as ArrowIcon } from 'src/ui/pages/Chat/assets/icons/arrow-down.svg';
import CustomDialog from 'src/ui/components/CustomDialog';

import chatApi, { type CreateChannelType } from 'src/api/chatApi';
import errorCodes from 'src/utils/errorCodes';
import type { IEmoji, IUser } from 'src/types';
import { useAppDispatch } from 'src/store';
import { ROUTES } from 'src/utils/constants';
import chatWs from 'src/api/ws/chatWs';
import handleValidationError, { checkIsAxiosError, checkIsValidationError } from 'src/utils/handleValidationError';
import { DEFAULT_EMOJI, emojies } from '../../constants';
import { chatSliceV2Actions } from '../../storeV2/chatSliceV2';
import { t } from 'src/utils/getTranslation';
import { useUser } from 'src/utils/hooks/general';
import CommonUsersMultiSelect from '../AddingUsersDialog/UserMultiSelect/CommonUsersMultiSelect';

const validationSchema = new YupObject().shape({
  name: new YupString().trim().required(errorCodes.validation.nameRequired),
  description: new YupString(),
  isLock: new YupBoolean(),
  isLimitedAccess: new YupBoolean(),
});

const CreatingChannelForm: React.FC<{
  isOpen: boolean;
  onClose?: () => void;
  onChannelCreated?: () => void;
}> = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useUser();
  const [isLoading, setLoading] = React.useState(false);
  const [anchor, setAnchor] = React.useState<HTMLElement | null>(null);

  const [selectedUsersForLimitedAccess, setSelectedUsersForLimitedAccess] = React.useState<number[]>([]);

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      icon: DEFAULT_EMOJI,
      isLock: false,
      isLimitedAccess: false,
    },
    validationSchema,
    onSubmit: async (values, formikHelpers) => {
      try {
        setLoading(true);
        if (values.name && user) {
          const newValues: CreateChannelType = {
            name: values.name.replace(/ {0,}/, ' ').trim(),
            description: values.description,
            icon: reactionIcon,
            isPrivate: values.isLock,
            limitedAccess: values.isLimitedAccess,
            writeAccessUsersIds: values.isLimitedAccess ? selectedUsersForLimitedAccess : undefined,
          };
          const response = await chatApi.createChannel(newValues);

          dispatch(chatSliceV2Actions.handleNewChannel({
            channel: response.data.payload,
          }));

          const createdChannel = response.data.payload;
          props.onChannelCreated?.();

          navigate(ROUTES.chat.createPath(createdChannel.channelId));
          chatWs.joinRoom(createdChannel.channelId);
        }
        handleCancel();
      } catch (err) {
        if (checkIsAxiosError(err)) {
          if (checkIsValidationError(err)) {
            return handleValidationError(err, formikHelpers.setFieldError);
          }

          toast.error(t(errorCodes.server.internalUnexpected));
        }
      } finally {
        setLoading(false);
      }
    },
  });

  const reactionIcon = React.useMemo(() => {
    return emojies[formik.values.icon || DEFAULT_EMOJI].skins[0].native;
  }, [formik.values.icon]);

  const handleCancel = () => {
    if (isLoading) {
      return;
    }
    props.onClose?.();
    formik.resetForm();
  };

  const toggleIsLock = () => {
    const newValue = !formik.values.isLock;
    formik.setFieldValue('isLock', newValue);
  };

  const toggleIsLimitedAccess = () => {
    const newValue = !formik.values.isLimitedAccess;
    formik.setFieldValue('isLimitedAccess', newValue);
  };

  const canContinue = React.useMemo(() => {
    return !formik.values.name.trim() || isLoading;
  }, [formik.values.name, isLoading]);

  const handleOpenReactionBar = (e: React.MouseEvent<HTMLElement>) => {
    setAnchor(e.target as HTMLElement);
  };

  const handleCloseReactionBar = () => {
    setAnchor(null);
  };

  const handleReactionSelect = (reaction: IEmoji) => {
    formik.setFieldValue('icon', reaction.id);
  };

  const handleSumbitUserSelection = (selectedUsers: IUser[]) => {
    setSelectedUsersForLimitedAccess(selectedUsers.map((user) => user.userId));
  };

  return (
    <StyledCreatingChannelForm
      isOpen={props.isOpen}
      handleCancel={handleCancel}
      title={(<CreateChannelFormTitle />)}
    >
      <form onSubmit={formik.handleSubmit} className="dialog__content">

        <Box display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="column" gap="6px" padding="4.5px 0px">
            <p className="channel-form__title-prompt">{t('chat:channels.creatingChannel.selectEmoji')}</p>
            <p className="channel-form__prompt">{t('chat:channels.creatingChannel.selectEmojiPrompt')}</p>
          </Box>

          <Box display="flex">
            <IconButton onClick={handleOpenReactionBar} className="channel-form__icon-button">
              <Typography className="channel-form__icon">
                {reactionIcon}
              </Typography>
              <ArrowIcon
                className={classNames(
                  'channel-form__arrow-icon',
                  { 'channel-form__arrow-icon--reverse': anchor },
                )}
                width="20px"
                height="20px"
              />
            </IconButton>
            <CustomEmojiPicker
              anchor={anchor}
              onEmojiSelect={handleReactionSelect}
              onClose={handleCloseReactionBar}
              popoverAnchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              popoverTransformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            />
          </Box>
        </Box>

        <CustomInput
          value={formik.values.name}
          onChangeValue={(ev) => formik.setFieldValue('name', ev.target.value)}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          error={t(formik.errors.name as any)}
          titleLabel={t('chat:channels.creatingChannel.name')}
          onBlur={formik.handleBlur}
          placeholder=" "
          fullWidth
          touched={formik.touched.name}
          textSize="large"
          name="name"
          maxLength={50}
        />

        <CustomInput
          value={formik.values.description}
          onChangeValue={(ev) => formik.setFieldValue('description', ev.target.value)}
          titleLabel={t('chat:channels.creatingChannel.description')}
          onBlur={formik.handleBlur}
          placeholder=" "
          promptLabel={t('chat:channels.creatingChannel.descriptionPrompt')}
          fullWidth
          touched={formik.touched.description}
          textSize="large"
          name="description"
          multiline
          rows={2}
        />

        <Box
          display="flex"
          flexDirection="column"
          gap="4px"
        >
          <InputLabel className="channel-form__switch-title">
            {t('chat:channels.creatingChannel.makePrivate')}
            <Switch
              checked={formik.values.isLock}
              onClick={toggleIsLock}
            />
          </InputLabel>
          <Typography
            variant="h6"
            className="channel-form__text"
          >
            {t('chat:channels.creatingChannel.makePrivatePrompt')}
          </Typography>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          gap="4px"
        >
          <InputLabel className="channel-form__switch-title">
            {t('chat:channels.creatingChannel.makeLimitedAccess')}
            <Switch
              checked={formik.values.isLimitedAccess}
              onClick={toggleIsLimitedAccess}
            />
          </InputLabel>
          <Typography
            variant="h6"
            className="channel-form__text"
          >
            {t('chat:channels.creatingChannel.makeLimitedAccessPrompt')}
          </Typography>
        </Box>

        {formik.values.isLimitedAccess && (
          <CommonUsersMultiSelect
            isLabelHideOnBlur
            onSubmit={handleSumbitUserSelection}
          />
        )}

        <ActionButtons
          cancelButton={{
            handleCancel,
            cancelButtonTitle: t('chat:channels.actionButtons.cancel'),
          }}
          submitButton={{
            disabled: canContinue,
            handleSubmit: formik.handleSubmit,
            submitButtonTitle: t('chat:channels.actionButtons.continue'),
          }}
        />
      </form>
    </StyledCreatingChannelForm>
  );
};

const CreateChannelFormTitle = () => {
  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        gap="6px"
        className="title__head"
      >
        <Typography variant="h3">
          {t('chat:channels.creatingChannel.openChannelTitle')}
        </Typography>
      </Box>

      <Typography
        variant="h5"
        className="title__prompt"
      >
        {t('chat:channels.creatingChannel.titleDescription')}
      </Typography>
    </Box>
  );
};

const StyledCreatingChannelForm = styled(CustomDialog)`
  .channel-form__text {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
  
  .channel-form__switch-title {
    display: flex;
    justify-content: space-between;
    font-size: ${({ theme }) => theme.font.size.sm};
    font-weight: ${({ theme }) => theme.font.weight.md};
    color: ${({ theme }) => theme.palette.text.tertiary};
  }

  .channel-form__arrow-icon path {
    stroke: ${({ theme }) => theme.palette.text.quaternary};
  }

  .channel-form__arrow-icon--reverse {
    transform: rotate(180deg);
  }

  .channel-form__title-prompt {
    font-size: ${({ theme }) => theme.font.size.sm};
    color: ${({ theme }) => theme.palette.text.primary};
    font-weight: ${({ theme }) => theme.font.weight.md};
  }

  .channel-form__prompt {
    font-size: ${({ theme }) => theme.font.size.xs};
    color: ${({ theme }) => theme.palette.text.secondary};
  }


  .channel-form__icon-button {
    max-width: 90px;
    max-height: 50px;
    gap: 10px;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;

    border: 1px solid ${({ theme }) => theme.palette.divider};
    border-radius: 8px;
    padding: 14px 20px;
    color: ${({ theme }) => theme.palette.text.primary};
  }

  .channel-form__icon {
    min-width: 18px;
    font-size: ${({ theme }) => theme.font.size.lg};
  }

  @media (max-width: ${({ theme }) => theme.screen.sm}px) {
    .dialog__paper {
      margin: 0px;
      width: 100%;
    }

    .channel-form__icon-button {
      padding: 14px 10px;
      justify-content: space-around;
    }
  }
`;

export default CreatingChannelForm;
