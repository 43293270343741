import styled from 'styled-components';
import MenuItem from '@mui/material/MenuItem';

type StyledUsersListItemPropsType = {
  isSelected: boolean;
  isChecked: boolean;
  isDisabled?: boolean;
};

const StyledUsersListItem = styled(MenuItem) <StyledUsersListItemPropsType>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 32px;
  margin-bottom: 10px;
  padding: 0px;
  background-color: ${({ isSelected, theme }) => (isSelected
    ? theme.palette.white.A5
    : theme.palette.select.optionBackground.primary)};
  
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};

  .user-container__default {
    padding: 6px 10px;
    display: flex;
    align-items: center;
    gap: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
    max-width: 360px;
  }

  .list-item__wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
  }

  .list-item__in-channel {
    color: ${({ theme }) => theme.palette.text.tertiary};
    font-weight: ${({ theme }) => theme.font.weight.sm};
    font-size: ${({ theme }) => (theme.font.size.xs)};
    padding-right: 10px;
  }

  .list-item__name {
    color: ${({ theme }) => theme.palette.grey[50]};
    font-weight: ${({ theme }) => theme.font.weight.lg};
    font-size: ${({ theme }) => (theme.font.size.sm)};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 250px;
  }

  .list-item__job-title {
    color: ${({ theme }) => theme.palette.grey[200]};
    font-weight: ${({ theme }) => theme.font.weight.sm};
    font-size: ${({ theme }) => theme.font.size.xs};
    flex-shrink: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .list-item__avatar {
    font-size: ${({ theme }) => theme.font.size.xs};
    width: 20px;
    height: 20px;
  }

  .checkedContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background-color: ${({ isChecked, theme }) => (isChecked
    ? theme.palette.primary[500]
    : theme.palette.background.hover)};
    border: 1px solid ${({ theme, isChecked }) => (isChecked
    ? theme.palette.primary[500]
    : theme.palette.white.A1)};
    border-radius: 4px;
    margin-right: 10px;
  }
`;

export default StyledUsersListItem;
