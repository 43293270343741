import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import Avatar from 'src/ui/components/Avatar';
import StatusBadge from 'src/legacy/ui/components/StatusBadge';
import JobPosition from 'src/ui/pages/Chat/components/JobPosition';
import { ReactComponent as CheckIcon } from 'src/ui/pages/Chat/assets/icons/add-check.svg';

import type { IUser } from 'src/types';
import StyledUsersListItem from './UsersListItem.styles';
import useTheme from 'src/utils/hooks/useTheme';
import { useUserOnlineStatus } from 'src/utils/hooks/general';
import getFullName from 'src/utils/getFullName';

type UserListItemPropsType = {
  user: IUser;
  handleClickListItem: (id: number) => void;
  selected?: number | null;
  isAlreadySelected?: boolean;
  handleSelect?: (id: number) => void;
  showSelected?: boolean;
  isSelected?: boolean;
};

const UserListItem: React.FC<UserListItemPropsType> = (props) => {
  const { t } = useTranslation(['chat', 'hrmEmployees']);
  const isOnline = useUserOnlineStatus(props.user.userId);
  const theme = useTheme();

  const handleClick = () => {
    if (props.isAlreadySelected) {
      return;
    }
    props.handleClickListItem(props.user.userId);
  };

  return (
    <StyledUsersListItem
      id={props.user.userId.toString()}
      isSelected
      aria-selected={props.selected !== props.user.userId}
      tabIndex={-1}
      onClick={handleClick}
      isDisabled={props.isAlreadySelected}
      isChecked={props.isSelected ?? false}
    >
      <div className="user-container__default">
        <div className="list-item__wrapper">
          <Avatar
            user={props.user}
            size="sx"
            pointerCursor
            className="list-item__avatar"
          />
          <Typography className="list-item__name">
            {getFullName(props.user)}
          </Typography>

          <StatusBadge
            isOnline={isOnline}
            customColor={{
              success: theme.palette.badge.success,
              secondary: theme.palette.badge.secondary,
            }}
          />
        </div>
        <JobPosition name={props.user.jobPosition?.name} />
      </div>

      {props.showSelected && !props.isAlreadySelected && (
        <div className="checkedContainer">
          <CheckIcon />
        </div>
      )}

      {props.isAlreadySelected && (
        <Typography className="list-item__in-channel">
          {t('chat:channels.addingUser.alreadyHere')}
        </Typography>
      )}
    </StyledUsersListItem>
  );
};

export default UserListItem;
