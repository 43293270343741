import type { IMediaItem } from './userTypes';

export enum ThemeENUM {
  light = 'light',
  dark = 'dark',
}

export enum LanguageENUM {
  en = 'en',
  ru = 'ru',
}

export type SizeOptionType = 'xs' | 'sm' | 'md' | 'lg' | 'xlg';

export type OptionType<T extends string | number | null> = {
  label: string;
  value: T;
};

export type SortingOptionType = {
  key: string;
  value: string;
};

export interface ICompany {
  companyId: number;
  createdAt: string | Date;
  updatedAt: string | Date;
  deletedAt: string | Date;
  name: string;
  subdomen: string;
  icon: string;
  logoMediaItemId?: number;
  logoMediaItem?: IMediaItem;
  mainChannelId: number;
  ownerId: number;
  companyHash: string;
}
