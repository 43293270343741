import React from 'react';
import styled from 'styled-components';

import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import LottieAnimation from 'src/ui/components/LottieAnimation';
import ChannelLink from './ChannelLink';
import { ReactComponent as LoupeIcon } from 'src/ui/pages/Chat/assets/icons/loupe.svg';
import Button from 'src/ui/components/Button';
import CustomInput from 'src/ui/components/CustomInput';
import CustomDialog from 'src/ui/components/CustomDialog/CustomDialog';

import { t } from 'src/utils/getTranslation';
import { useChannelsOverview } from './ChannelsOverview.hooks';
import { useMainChannel } from '../../Chat.hooks';

const ChannelsOverview: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = (props) => {
  const {
    isChannelListLoading,
    channelList,
    channelListTotalCount,
    searchValue,
    onModalClose,
    handleCreateChannelClick,
    handleSearchStringChange,
    handleSubmitLeaveChannel,
    handleJoinClick,
    navigateToChannel,
    getSuffixesByNumber,
  } = useChannelsOverview({
    isOpen: props.isOpen,
    handleClose: props.onClose,
  });

  const { mainChannel } = useMainChannel();

  return (
    <StyledChannelsOverview
      isOpen={props.isOpen}
      handleCancel={onModalClose}
      title={(
        <div>
          <Typography
            variant="h3"
            className="title__head"
          >
            {t('chat:channels.channelOverview.title')}
          </Typography>
          <Typography
            variant="h5"
            className="title__prompt"
          >
            {t('chat:channels.channelOverview.description')}
          </Typography>
        </div>
      )}
    >
      <CustomInput
        value={searchValue}
        onChangeValue={handleSearchStringChange}
        fullWidth
        placeholder={t('chat:channels.channelOverview.findChannel')}
        endAdornment={(
          <LoupeIcon className="input-end-icon" />
        )}
      />

      <div className="create-channel__container">
        <Typography
          variant="h5"
          className="channels-count__title"
        >
          {channelListTotalCount} {t('chat:channels.channelOverview.channel')}{getSuffixesByNumber(channelListTotalCount)}
        </Typography>

        <Button
          size="small"
          className="create-channel__button"
          variant="text"
          onClick={handleCreateChannelClick}
        >
          + {t('chat:channels.channelOverview.createNew')}
        </Button>
      </div>

      <Collapse in={props.isOpen}>
        {isChannelListLoading && (
          <LottieAnimation animation="circleLoader" className="button__loader-icon" />
        )}

        {(!isChannelListLoading || Boolean(channelList.length)) && (
          <div className="channel-list__container">
            {channelList.map((channel) => {
              return (
                <div
                  key={channel.channelId}
                  className="channels-list__link"
                >
                  <ChannelLink
                    channel={channel}
                    navigateToChannel={navigateToChannel}
                    handleSubmitLeaveChannel={handleSubmitLeaveChannel}
                    handleJoinClick={handleJoinClick}
                    channelListTotalCount={channelListTotalCount}
                    getSuffixesByNumber={getSuffixesByNumber}
                    isMain={mainChannel.channelId === channel.channelId}
                  />
                </div>
              );
            })}
          </div>
        )}
      </Collapse>
    </StyledChannelsOverview >
  );
};

const StyledChannelsOverview = styled(CustomDialog)`
  .channel-list__container {
    max-height: 395px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 5px;
    &::-webkit-scrollbar {
      visibility: hidden;
    }
    &::-webkit-scrollbar {
      width: 8px;
    }
  }
  
  .channels-list__link {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
    
    &:hover {
      .channel-link {
        background-color: ${({ theme }) => theme.palette.white.A4};
        border-radius: 6px;
        cursor: pointer;
      }
    }
  }

  .create-channel__container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 14px;
  }

  .create-channel__button {
    padding: 0px;
    height: 18px;
  }

  .channels-count__title {
    color: ${({ theme }) => theme.palette.grey[500]};
    font-weight: ${({ theme }) => theme.font.weight.lg};
  }

  .button__loader-icon {
    height: 1em;
  }

  .input-end-icon {
    padding-right: 15px;
    width: 18px;
    height: 18px;
  }

  .title__prompt {
    margin-bottom: 12px;
    font-weight: ${({ theme }) => theme.font.weight.sm};
    font-size: ${({ theme }) => theme.font.size.sm};
    color: ${({ theme }) => theme.palette.grey[200]};
  }

  .title__head {
    font-size: ${({ theme }) => theme.font.size.xlg};
  }

  .dialog__content {
    gap: 6px;
  }
`;

export default ChannelsOverview;
