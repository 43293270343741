import React from 'react';

import Avatar from 'src/ui/components/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { ReactComponent as CloseIcon } from 'src/ui/pages/Chat/assets/icons/close-2.svg';
import StyledSelectedUserCard from './SelectedUserCard.styles';

import type { IUser } from 'src/types';

type SelectedUserCardPropsType = {
  user: IUser;
  isFocused: boolean;
  handleDelete: (id: number) => void;
  selectedCardRef: React.MutableRefObject<HTMLButtonElement | null>;
};

const SelectedUserCard: React.FC<SelectedUserCardPropsType> = (props) => {
  const handleKeyDown: React.KeyboardEventHandler<HTMLButtonElement> = (ev) => {
    if (props.isFocused && ev.key === 'Backspace') {
      props.handleDelete(props.user.userId);
    }
  };

  return (
    <StyledSelectedUserCard>
      <Avatar
        user={props.user}
        size="sx"
        className="user-card__avatar"
      />
      <Typography
        variant="h6"
        className="user-card__text"
      >
        {props.user.fullName}
      </Typography>
      <IconButton
        ref={props.isFocused ? props.selectedCardRef : null}
        autoFocus={props.isFocused}
        onKeyDown={handleKeyDown}
        onClick={() => props.handleDelete(props.user.userId)}
        className="user-card__icon-button"
      >
        <CloseIcon />
      </IconButton>
    </StyledSelectedUserCard>
  );
};

export default SelectedUserCard;
