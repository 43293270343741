import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import Typography from '@mui/material/Typography';
import LottieAnimation from 'src/ui/components/LottieAnimation';

import UserListItem from './UserListItem';

import type { IUser } from 'src/types';

type PropsType = {
  optionList: IUser[];
  size: 'small' | 'middle';
  selectedOption: number | null;
  handleSelect: (id: number | null) => void;
  handleClickListItem: (id: number) => void;
  handleListClickAway: () => void;
  userIds?: number[];
  isLoading?: boolean;
  selectedIds?: number[];
  isHeaderSelect?: boolean;
};

const OptionsList: React.FC<PropsType> = (props) => {
  const { t } = useTranslation('chat');

  const handleClickAway = () => {
    props.handleListClickAway();
    props.handleSelect(null);
  };

  const handleClick = (id: number) => {
    props.handleClickListItem(id);
    props.handleSelect(null);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classnames(
        'list-container',
        {
          'select__list--middle': props.size === 'middle',
          'list-container--absolute': props.isHeaderSelect,
        },
      )}
      >
        <ul
          role="listbox"
          tabIndex={-1}
          aria-multiselectable={false}
          className={classnames(
            'select__list',
            {
              'select__list--is-loading': props.isLoading && !props.isHeaderSelect,
            },
          )}
        >
          {props.isLoading
            ? (
              <LottieAnimation animation="circleLoader" className="loader-icon" />
            )
            : (
              <div>
                {props.optionList.length
                  ? props.optionList.map((user) => {
                    const isSelected = props.selectedIds
                      ? Boolean(props.selectedIds.find((item) => item === user.userId))
                      : false;
                    return (
                      <UserListItem
                        user={user}
                        key={user.userId}
                        handleClickListItem={() => handleClick(user.userId)}
                        selected={props.selectedOption}
                        handleSelect={() => props.handleSelect(user.userId)}
                        isAlreadySelected={props.userIds?.includes(user.userId)}
                        showSelected
                        isSelected={isSelected}
                      />
                    );
                  })
                  : (
                    <Typography
                      variant="h2"
                      className="select__no-result"
                    >
                      {t('channels.addingUser.usersNotFound')}
                    </Typography>
                  )}
              </div>
            )
          }
        </ul>
      </div>
    </ClickAwayListener>
  );
};

export default OptionsList;
