import qs from 'query-string';
import getStore from 'src/store/getStore';

export interface IChatRouteParams {
  channelId: number | string;
  messageId?: number | string;
  parentMessageId?: number | string;
}

class ChatRouter {
  private baseUrl = '/chat';

  public getChatUrl(params: {
    channelId: number | string;
    messageId?: number | string | null;
    parentMessageId?: number | string | null;
    profileId?: number | string | null;
    company?: string;
  }) {
    const companyHash = getStore().main.user?.company?.companyHash;

    const queryParams = qs.stringify({
      parentMessageId: params.parentMessageId,
      messageId: params.messageId,
      profileId: params.profileId,
    }, { skipEmptyString: true, skipNull: true });

    return `/${params.company || companyHash}${this.baseUrl}/${params.channelId}${queryParams ? `?${queryParams}` : ''}`;
  }

  public isSamePath(pathNameLeft: string, pathNameRight: string) {
    return pathNameLeft === pathNameRight;
  }

  public isChatPage(pathName: string) {
    return pathName.includes('chat');
  }
}

export default new ChatRouter();
