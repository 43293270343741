import React from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CustomInput from 'src/ui/components/CustomInput';
import OptionsList from './OptionsList';
import SelectedUserCard from './SelectedUserCard';
import StyledUserMultiSelect from './UserMultiSelect.styles';

import type { IUser } from 'src/types';
import { t } from 'src/utils/getTranslation';
import { useTabletCheck } from 'src/utils/hooks/useMediaQueryCheck';

type Props = {
  isAddUsers?: boolean;
  isHeaderSelect?: boolean;
  selectedUsers: IUser[];

  isVisibleSearchResults: boolean;

  searchValue: string;
  searchInputName?: string;

  userList: IUser[];
  optionsUserIds?: number[];
  isOptionsListLoading?: boolean;

  isLabelHideOnBlur?: boolean;

  onChangeSearchValue?: (value: string) => void;
  onSelect: (updatedList: IUser[]) => void;
  setSkipButton?: () => void;
  setAddButton?: () => void;

  placeholder?: string;
};

const UserMultiSelect: React.FC<Props> = (props) => {
  const isTablet = useTabletCheck();

  const [isListVisible, setIsListVisible] = React.useState(!props.isHeaderSelect);
  const selectedCardRef = React.useRef<HTMLButtonElement | null>(null);
  const [selectedOption, setSelectedOption] = React.useState<number | null>(null);
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const [isHideLabel, setHideLabel] = React.useState(props.isLabelHideOnBlur ?? false);
  const [focusedSelectedUserId, setFocusedSelectedUserId] = React.useState<number | null>(null);

  const placeholder = !props.selectedUsers.length ? (props.placeholder || '@somebody') : ' ';

  const selectedUsersId = React.useMemo(() => {
    return props.selectedUsers.map((user) => user.userId);
  }, [props.selectedUsers]);

  const handleSearchStringChange: React.ChangeEventHandler<HTMLInputElement> = (ev) => {
    if (props.isHeaderSelect) {
      handleOpenList();
    }
    props.onChangeSearchValue?.(ev.target.value);
  };

  const handleOpenList = () => {
    setIsListVisible(true);
  };

  const handleListClickAway = () => {
    if (document.activeElement?.tagName !== 'INPUT' && props.isHeaderSelect) {
      setIsListVisible(false);
    }
  };

  const handleChangeSelectedOption = (id: number | null) => {
    setSelectedOption(id);
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (ev) => {
    // eslint-disable-next-line default-case
    switch (ev.key) {
      case 'Backspace':
        if (props.searchValue === '') {
          selectedCardRef?.current?.focus();
        }
        break;
      case 'ArrowDown':
        if (!isListVisible) {
          setIsListVisible(true);
        } else {
          const currentOptionIndex = props.userList.findIndex((user) => {
            return user.userId === selectedOption;
          });
          if (currentOptionIndex + 1 === props.userList.length) {
            setSelectedOption(props.userList[0].userId);
          } else {
            setSelectedOption(props.userList[currentOptionIndex + 1].userId);
          }
        }
        break;
      case 'ArrowUp':
        if (!isListVisible) {
          ev.preventDefault();
        } else {
          const currentOptionIndex = props.userList.findIndex((user) => {
            return user.userId === selectedOption;
          });
          if (currentOptionIndex <= 0) {
            setSelectedOption(props.userList[props.userList.length - 1].userId);
          } else {
            setSelectedOption(props.userList[currentOptionIndex - 1].userId);
          }
        }
        break;
      case 'Enter': {
        ev.preventDefault();
        if (selectedOption) {
          handleClickListItem(selectedOption);
        }
        break;
      }
    }
  };

  const handleClickListItem = (id: string | number) => {
    const isAdded = props.selectedUsers.find((user) => user.userId === id);
    if (isAdded) {
      const user = props.userList.find((user) => user.userId === id);
      if (user) {
        const updatedSelectedUsers = props.selectedUsers.filter((item) => item.userId !== id);
        props.onSelect(updatedSelectedUsers);
      }
      return;
    }

    const user = props.userList.find((user) => user.userId === id);
    if (user) {
      const updatedSelectedUsers = [...props.selectedUsers, user];
      props.onSelect(updatedSelectedUsers);
    }
  };

  const handleBlur = () => {
    if (props.isLabelHideOnBlur) {
      setHideLabel(true);
    }
    if (props.searchValue === '' && props.setSkipButton) {
      props.setSkipButton();
    }
  };

  const handleFocus = () => {
    setIsListVisible(true);
    if (props.isLabelHideOnBlur) {
      setHideLabel(false);
    }
    if (props.setAddButton) {
      props.setAddButton();
    }
  };

  const handleDeleteSelectUser = (id: number) => {
    const updatedSelectedUsers = props.selectedUsers.filter((user) => user.userId !== id);
    props.onSelect(updatedSelectedUsers);
    if (props.setSkipButton && !updatedSelectedUsers.length) {
      props.setSkipButton();
    }
    if (!isTablet) {
      inputRef?.current?.focus();
    }
  };

  React.useEffect(() => {
    if (!props.selectedUsers.length) {
      setFocusedSelectedUserId(null);
      return;
    }
    setFocusedSelectedUserId(props.selectedUsers[props.selectedUsers.length - 1].userId);
    if (!isTablet) {
      inputRef?.current?.focus();
    }
  }, [props.selectedUsers]);

  React.useEffect(() => {
    if (props.userList.length) {
      handleChangeSelectedOption(props.userList[0].userId);
    }
  }, [props.userList, props]);

  return (
    <StyledUserMultiSelect
      isVisible={props.isHeaderSelect ? isListVisible : true}
      isHideLabel={isHideLabel}
      isAddUsers={Boolean(props.isAddUsers)}
    >
      <Box className="select" display="flex" flexDirection="column">

        {props.isHeaderSelect
          ? (
            <Box className="select__container">
              {props.selectedUsers.map((user) => (
                <SelectedUserCard
                  key={user.userId}
                  user={user}
                  handleDelete={handleDeleteSelectUser}
                  isFocused={focusedSelectedUserId === user.userId}
                  selectedCardRef={selectedCardRef}
                />
              ))}
              <TextField
                value={props.searchValue}
                onChange={handleSearchStringChange}
                onKeyDown={handleKeyDown}
                placeholder="@somebody"
                autoComplete="off"
                variant="standard"
                inputRef={(inputRef) => (inputRef && !props.isVisibleSearchResults) && inputRef.focus()}
                classes={{
                  root: 'select__text-field',
                }}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    input: 'select__text-input',
                  },
                }}
              />
            </Box>)
          : (
            <Box className="select__select">
              <CustomInput
                textSize="medium"
                titleLabel={t('chat:channels.addingUser.name')}
                autocomplete="off"
                placeholder={placeholder}
                value={props.searchValue}
                inputRef={inputRef}
                fullWidth
                onChangeValue={handleSearchStringChange}
                onBlur={handleBlur}
                handleKeyDown={handleKeyDown}
                onFocus={handleFocus}
                name={props.searchInputName}
                startAdornment={(
                  <>
                    {props.selectedUsers.map((user) => (
                      <SelectedUserCard
                        key={user.userId}
                        user={user}
                        handleDelete={handleDeleteSelectUser}
                        isFocused={focusedSelectedUserId === user.userId}
                        selectedCardRef={selectedCardRef}
                      />
                    ))}
                  </>
                )}
                isSelect={Boolean(props.selectedUsers.length)}
              />
            </Box>
          )}

        <OptionsList
          optionList={props.userList}
          selectedOption={selectedOption}
          handleSelect={handleChangeSelectedOption}
          handleClickListItem={handleClickListItem}
          handleListClickAway={handleListClickAway}
          size={props.isHeaderSelect ? 'middle' : 'small'}
          userIds={props.isHeaderSelect ? undefined : props.optionsUserIds}
          isLoading={props.isOptionsListLoading}
          selectedIds={selectedUsersId}
          isHeaderSelect={props.isHeaderSelect}
        />
      </Box>
    </StyledUserMultiSelect >
  );
};

export default UserMultiSelect;
