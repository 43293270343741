import React from 'react';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';

import MenuItem from './MenuItem';
import WorkspaceItem from './WorkspaceItem';
import ToggleSoundButton from './ToggleSoundButton';
import UserMenuAreaStyle from './UserMenuArea.styles';
import Protector from 'src/ui/components/Protector';

import settingsIcon from '../assets/settings.svg';
import workersIcon from '../assets/workers.svg';
import logoutIcon from '../assets/logout.svg';
import { useAppDispatch, useAppSelector } from 'src/store';
import { mainSliceActions } from 'src/store/mainSlice/mainSlice.reducer';
import { UserPermissionsENUM, type IUser } from 'src/types';
import storage from 'src/utils/storage';
import helpers from 'src/utils/helpers';
import { checkIsAxiosError, checkIsValidationError } from 'src/utils/handleValidationError';
import { ROUTES } from 'src/utils/constants';
import { t } from 'src/utils/getTranslation';
import { useWorkspacesList } from 'src/utils/hooks/general';
import authApi from 'src/api/authApi';

const UserMenuArea: React.FC<{
  setAnchor: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
  className?: string;
}> = (props) => {
  const navigate = useNavigate();
  const user = useAppSelector(({ main }) => main.user) as IUser;
  const dispatch = useAppDispatch();

  const { checkedWorkspacesIds } = useWorkspacesList();

  const currentWorkspace = user?.company;

  if (!currentWorkspace) {
    return null;
  }

  const onLogoutWorkspace = async () => {
    try {
      const updatedSelectedWorkspaces = checkedWorkspacesIds.filter((id) => id !== currentWorkspace.companyId);
      dispatch(mainSliceActions.setSelectedWorkspaces(updatedSelectedWorkspaces));
      const newWorkspaceId = checkedWorkspacesIds.find((companyId) => {
        return companyId !== currentWorkspace.companyId;
      });
      if (!newWorkspaceId) {
        return;
      }

      const response = await authApi.changeCompany(newWorkspaceId);

      storage.authToken.set(response.data.meta.authToken);
      storage.refreshToken.set(response.data.meta.refreshToken);

      const isRedirected = helpers.replaceSubdomain(response.data.payload.company!.subdomen, {
        companyHash: response.data.payload.company!.companyHash,
      });

      if (!isRedirected) {
        helpers.goHome();
      }
    } catch (err) {
      if (checkIsAxiosError(err) && checkIsValidationError(err)) {
        toast.error(t('errors:server.validation.failed'));
      }
      console.error('Failed to logout:', err);
    }
  };

  const handleInviteClick = () => {
    props.setAnchor(null);
    navigate(ROUTES.createUser.createPath());
  };

  return (
    <UserMenuAreaStyle className={props.className}>
      <div className="area-title">
        <WorkspaceItem
          workspaceItem={{
            companyId: currentWorkspace.companyId,
            subdomen: currentWorkspace.subdomen,
          }}
          setAnchor={props.setAnchor}
        />
        <div>
          <div className="company-title">
            {user?.company?.name}
          </div>
          <div className="domain-title">
            {`${user?.company?.subdomen}${t('authPages:signUp.workSpaceInput.domain.adornment')}`}
          </div>
        </div>
      </div>

      <>
        <ToggleSoundButton />

        <Protector permissions={[UserPermissionsENUM.company__settings_write]}>
          <Link
            to={ROUTES.workspaceSettings.createPath()}
            className="menu-item-link"
          >
            <MenuItem
              setAnchor={props.setAnchor}
              menuPunktIcon={settingsIcon}
              menuPunktTitle={t('general:userMenu.settingArea')}
            />
          </Link>
        </Protector>

        <Protector permissions={[UserPermissionsENUM.hrm__available]}>
          <div className="workers">
            <Link
              to={ROUTES.employees.createPath()}
            >
              <MenuItem
                setAnchor={props.setAnchor}
                menuPunktIcon={workersIcon}
                menuPunktTitle={t('general:userMenu.workers')}
              />
            </Link>

            <Protector permissions={[UserPermissionsENUM.hrm__user_invite]}>
              <Link to={ROUTES.createUser.createPath()} onClick={() => props.setAnchor(null)}>
                <p onClick={handleInviteClick} className="invite">{t('general:userMenu.invite')}</p>
              </Link>
            </Protector>
          </div>
        </Protector>
      </>

      {checkedWorkspacesIds.length > 1 && (
        <MenuItem
          onClick={onLogoutWorkspace}
          setAnchor={props.setAnchor}
          menuPunktIcon={logoutIcon}
          menuPunktTitle={t('general:userMenu.logoutArea')}
        />
      )}
    </UserMenuAreaStyle >
  );
};

export default UserMenuArea;
