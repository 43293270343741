import http from './http';
import type { ResponseType } from './http';
import type { IUser, ICompany } from 'src/types';
import qs from 'query-string';
import type { ICompanyUnreadMessagesMeta } from 'src/types/workspaces';

const companiesPath = '/companies';

type CreateWorkspaceDataType = {
  email: string;
  workspaceName: string;
};

type UpdateWorkspaceDataType = {
  logoMediaItemId?: number;
};

const createWorkspace = (data: CreateWorkspaceDataType) => {
  return http.post<ResponseType<IUser>>(companiesPath, data);
};

const updateWorkspace = (id: number, data: UpdateWorkspaceDataType) => {
  return http.patch<ResponseType<ICompany>>(`${companiesPath}/${id}`, data);
};

const getUnreadMessagesCountByCompanies = (params: { ids: number[] }) => {
  return http.get<ResponseType<ICompanyUnreadMessagesMeta>>(`${companiesPath}/unread-messages-count`, {
    params: {
      companies: params.ids,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'comma' });
    },
  });
};

export default {
  createWorkspace,
  updateWorkspace,
  getUnreadMessagesCountByCompanies,
};
