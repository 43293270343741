import React from 'react';
import { transliterate } from 'src/utils/highlighted';
import useDebouncedState from 'src/utils/hooks/useDebouncedState';
import { useUserList } from 'src/utils/hooks/usersCollection/useUsersList';
import UserMultiSelect from './UserMultiSelect';
import type { IUser } from 'src/types';
import { t } from 'src/utils/getTranslation';

type Props = {
  isLabelHideOnBlur?: boolean;
  isHeaderSelect?: boolean;
  onSubmit: (selectedUsers: IUser[]) => void;
  initialSelectedUsers?: IUser[];
};

const CommonUsersMultiSelect: React.FC<Props> = (props) => {
  const [selectedUsers, setSelectedUsers] = React.useState<IUser[]>(props.initialSelectedUsers || []);

  const [value, setValue] = React.useState('');
  const search = useDebouncedState(value, (newValue) => setValue(newValue), 100);
  const { listOfUsersByWorkspace } = useUserList();

  const membersList = React.useMemo(() => {
    const users = listOfUsersByWorkspace
      .filter((user) => !user.deletedAt);

    if (!search.debouncedValue) {
      return users;
    }
    const { ruResult, engResult } = transliterate(value);
    const filteredUsers = users?.filter((user) => {
      const fullName = user?.fullName?.toLowerCase();
      return fullName?.includes(ruResult) || fullName?.includes(engResult);
    });
    return filteredUsers;
  }, [search.debouncedValue, listOfUsersByWorkspace]);

  const handleSelect = (updatedList: IUser[]) => {
    setSelectedUsers(updatedList);
    props.onSubmit(updatedList);
    search.set('');
  };

  const onInputChange = (value: string) => {
    search.set(value);
  };

  return (
    <UserMultiSelect
      isAddUsers
      isVisibleSearchResults

      placeholder={t('chat:channels.addingUser.placeholder')}
      searchValue={search.value}
      searchInputName="common-users-search"

      selectedUsers={selectedUsers}
      userList={membersList}
      isHeaderSelect={props.isHeaderSelect}

      isLabelHideOnBlur={props.isLabelHideOnBlur}
      onChangeSearchValue={onInputChange}
      onSelect={handleSelect}
    />
  );
};

export default CommonUsersMultiSelect;
