import type { LanguageENUM, ThemeENUM, DraftDataType } from 'src/types';
import LocalStorageItem from './LocalStorageItem';
import CookieItem from './CookieItem';
import type { RouteKeyType } from '../constants';
import type { UnsentMessagesType } from 'src/types/chatTypes';
import config from 'src/config';

// const cookieDomainValue = !isLocalPWA ? `.${config.cookieDomain}` : undefined;
const cookieDomainValue = `.${config.cookieDomain}`;

export { LocalStorageItem, CookieItem };

type CreateWorkspaceType = {
  email: string;
  workspace: string;
  startTime?: number;
  fullName?: string;
  phone?: string;
  phoneConfirmed?: boolean;
  agree?: boolean;
};

const storage = {
  authToken: new CookieItem<string>({ key: 'authToken', defaultOptions: { domain: cookieDomainValue, expires: 365 } }),
  refreshToken: new CookieItem<string>({ key: 'refreshToken', defaultOptions: { domain: cookieDomainValue, expires: 365 } }),
  selectedWorkspaces: new CookieItem<number[]>({ key: 'checkedAccounts', defaultValue: [], defaultOptions: { domain: cookieDomainValue, expires: 365 * 100 } }),
  deviceId: new CookieItem<string>({ key: 'deviceId', defaultOptions: { domain: cookieDomainValue, expires: 365 * 100 } }),
  theme: new LocalStorageItem<ThemeENUM>({ key: 'theme' }),
  language: new LocalStorageItem<LanguageENUM>({ key: 'language' }),
  favoritesLinks: new LocalStorageItem<RouteKeyType[]>({ key: 'favoritesLinks', defaultValue: [] }),
  sidebarWidth: new LocalStorageItem<number>({ key: 'sidebarWidth', defaultValue: 215 }),
  threadWidth: new LocalStorageItem<number>({ key: 'threadWidth', defaultValue: 310 }),
  hrmSidebarWidth: new LocalStorageItem<number>({ key: 'sidebarWidth', defaultValue: 229 }),
  chatDrafts: new LocalStorageItem<DraftDataType>({ key: 'chatDrafts', defaultValue: { channels: {}, threads: {} } }),
  unsentMessages: new LocalStorageItem<UnsentMessagesType>({ key: 'unsentMessages', defaultValue: {} }),
  workspaceIsCreating: new LocalStorageItem<CreateWorkspaceType | null>({ key: 'workspaceIsCreating' }),
  isChannelListOpened: new LocalStorageItem<boolean | null>({ key: 'isChannelListOpened' }),
  isDMListOpened: new LocalStorageItem<boolean | null>({ key: 'isDMListOpened' }),
  soundController: new LocalStorageItem<'on' | 'off'>({ key: 'soundController', defaultValue: 'on' }),
};

// To refresh expiration date
storage.selectedWorkspaces.set(storage.selectedWorkspaces.get()!);
storage.deviceId.set(storage.deviceId.get()!);

export default storage;
